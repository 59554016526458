import { useState, useEffect, useCallback } from 'react';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Eye, EyeSlash } from 'react-bootstrap-icons';
import logo from '../../assets/logo-black.svg';
const apiBaseUrl = process.env.REACT_APP_API_URL;
const isAdmin = window.location.pathname.includes('/admin');
const adminPath = isAdmin ? '/admin' : '';
const apiUrl = `${apiBaseUrl}${adminPath}/login`;

export default function Login({ props }) {
	const { setToken, setUsername } = props;
	const [formData, setFormData] = useState({ username: '', password: '' });
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState();
	const [validated, setValidated] = useState(false);
	const [showPwd, setShowPwd] = useState(false);

	const handleInputChange = e => {
		const { name, value } = e.target;
		setFormData(prevForm => ({ ...prevForm, [name]: value }));
		setValidated(false);
	};

	const handleShowPwd = () => {
		setShowPwd(!showPwd);
	};

	const handleSubmit = async e => {
		e.preventDefault();
		setError(false);

		const { username, password } = formData;
		if (!username || !password) {
			return setValidated(true);
		}

		setLoading(true);
	};

	const loginUser = useCallback(async () => {
		return fetch(apiUrl, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(formData),
		})
			.then(data => data.json())
			.then(jsonData => {
				if (jsonData.token) {
					setUsername(formData.username);
					setToken(jsonData.token);
				} else {
					setError(jsonData.message);
				}
			})
			.catch(err => console.log(err));
	}, [formData, setToken, setUsername]);

	useEffect(() => {
		if (loading) {
			loginUser().then(() => setLoading(false));
		}
	}, [loading, loginUser]);

	return (
		<Container fluid>
			<Row>
				<Col md={6} className="vh-100- vh-sm-100 d-flex flex-column align-items-center- justify-content-between px-5 py-5">
					<img
						src={logo}
						width='300'
						className='position-absolute- mt-5-'
						alt='Prioriza CT'
					/>
					<Row className='mt-5 pt-2'>
						<h1 className='pb-4'>
							Bem-vindo à PRIORIZA Centro de Treinamentos
						</h1>
						<p>
							<a href='https://prioriza.eng.br/PRIORIZA-CT-formulario.xlsx'>Baixe a planilha</a> para fazer uma inscrição individual ou em grupo, preenchendo com nome e CPF de cada aluno.
						</p>
						<p>
							Envie a planilha preenchida para o e-mail <a href='mailto:ct@prioriza.eng.br'>ct@prioriza.eng.br</a>
						</p>
						<p>
							Você vai receber a fatura por e-mail, e assim que o pagamento for identificado, receberá os dados de acesso ao treinamento.
						</p>
						<p>
							Assista as vídeo aulas e realize a prova em até 30 dias, com até 5 tentativas para aprovação, e acesse seu certificado assim que aprovado.
						</p>
					</Row>
					<p className='align-self-baseline small d-none d-sm-block pt-5 pt-lg-0'>
						<a href='mailto:ct@prioriza.eng.br'>ct@prioriza.eng.br</a><br />
						+55 41 3209-0390<br />
						Av. Presidente Affonso Camargo, 881, Sala 1102<br />
						Cristo Rei - Curitiba, PR
					</p>
				</Col>
				<Col md={6} className='login-img vh-100- d-flex flex-column align-items-center justify-content-between py-5'>
					{/* <Container className="vh-100 d-flex flex-column align-items-center justify-content-between py-5"> */}
					<div></div>
					<Card border="light" className="mx-auto-">
						<Card.Body>
							<Card.Title className="mb-3">Login</Card.Title>
							<Form noValidate validated={validated} onSubmit={handleSubmit}>
								<Form.Group className="mb-3" controlId="formBasicEmail">
									<Form.Label>Usuário</Form.Label>
									<Form.Control
										required
										type='text'
										placeholder='Digite o nome do usuário'
										name='username'
										value={formData.username}
										onChange={handleInputChange}
									/>
								</Form.Group>
								<Form.Group className='mb-4' controlId='formBasicPassword'>
									<Form.Label>Senha</Form.Label>
									<InputGroup className='mb-3'>
										<Form.Control
											required
											type={showPwd ? 'text' : 'password'}
											placeholder='Digite a senha'
											name='password'
											value={formData.password}
											onChange={handleInputChange}
										/>
										<Button variant='secondary' onClick={handleShowPwd}>
											{
												showPwd ? <Eye size={18} className='mb-1' />
													: <EyeSlash size={18} className='mb-1' />
											}
										</Button>
									</InputGroup>
								</Form.Group>
								<Stack gap={2} className="col-md-3- mx-auto-">
									<Button
										variant='primary'
										type='submit'
										disabled={loading}
										onClick={!loading ? handleSubmit : null}
									>
										{loading ?
											<>
												<span>Entrando…</span>
												<span className='spinner-border spinner-border-sm float-end mt-1' role='status'>
												</span>
											</>
											: 'Entrar'
										}
									</Button>
								</Stack>
							</Form>
							{
								error &&
								<Alert variant='danger' className='mt-4'>
									{error}
								</Alert>
							}
						</Card.Body>
					</Card>
					<blockquote className='position-absolute- bottom-0- blockquote- text-center text-white pt-5 pt-sm-0'>
						<p className='mb-0'>
							Mais arriscado que mudar, é continuar fazendo a mesma coisa.
						</p>
						<cite>Peter Drucker</cite>
					</blockquote>
					{/* </Container> */}
				</Col>
				<p className='text-center small py-4 d-sm-none'>
					<a href='mailto:ct@prioriza.eng.br'>ct@prioriza.eng.br</a><br />
					+55 41 3209-0390<br />
					Av. Presidente Affonso Camargo, 881, Sala 1102<br />
					Cristo Rei - Curitiba, PR
				</p>
			</Row>
		</Container >
	)
}
