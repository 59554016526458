import Table from 'react-bootstrap/Table';
// import Dropdown from 'react-bootstrap/Dropdown';

export default function TableStudents({ props }) {
	const { students } = props;

	const statusColorMap = {
		'ativo': 'primary',
		'aprovado': 'success',
		'reprovado': 'danger'
	};

	const convertDate = date => {
		const dateObj = new Date(date);
		return dateObj.toLocaleDateString('pt-BR', {
			day: '2-digit', month: '2-digit', year: 'numeric'
		});
	}

	return (
		<Table striped bordered hover>
			<thead>
				<tr>
					<th>Nome</th>
					<th>CPF</th>
					<th>Empresa</th>
					<th>Matrícula</th>
					<th>Status</th>
					<th>Nota</th>
				</tr>
			</thead>
			<tbody>
				{students.map(student => {
					// const overdue = student.enrollments[0]?.overdue;
					const { statusVirtual, grade } = student.enrollments[0];
					return (
						<tr key={student._id}>
							<td>
								<a href={`alunos/${student.cpf}`}>
									{student.name}
								</a>
								{/* <Dropdown drop="end">
									<Dropdown.Toggle variant="link">
										{student.name}
									</Dropdown.Toggle>
									<Dropdown.Menu variant="dark">
										<Dropdown.Item href={`alunos/${student.cpf}`}>Ver</Dropdown.Item>
										<Dropdown.Item href={`alunos/${student.cpf}/editar`}>Editar</Dropdown.Item>
										<Dropdown.Item data-student={JSON.stringify(student)} onClick={handleDelete}>Remover</Dropdown.Item>
									</Dropdown.Menu>
								</Dropdown> */}
							</td>
							<td>
								{student.cpf}
							</td>
							<td>
								{student.company.company_name}
							</td>
							<td>
								{convertDate(student.enrollments[0].createdAt)}
							</td>
							<td className={`text-${statusColorMap[statusVirtual]}`}>
								{statusVirtual}
							</td>
							<td className={`text-${statusColorMap[statusVirtual]}`}>
								{grade}
							</td>
						</tr>
					)
				})}
			</tbody>
		</Table >
	)
}
