import { useState } from 'react';

export default function useMessage() {
	const [message, setMessage] = useState(null);

	return {
		message,
		setMessage
	}
}
