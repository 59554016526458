import {
	BrowserRouter as Router,
	Routes,
	Route,
	Navigate
} from 'react-router-dom';
import useToken from '../../hooks/useToken';
import useMessage from '../../hooks/useMessage';
import Login from '../../pages/Login';
// import Admin from '../../pages/Admin';
import Students from '../../pages/Students';
import Student from '../../pages/Student';
import FormStudent from '../../components/FormStudent';
import FormCompany from '../../components/FormCompany';
import Companies from '../../pages/Companies';
import Training from '../../pages/Training';
import './styles.scss';

export default function App() {
	const { token, getToken, setToken, setStatusCode, clearToken, username, setUsername } = useToken();
	const { message, setMessage } = useMessage();
	const props = { token, getToken, setToken, setStatusCode, clearToken, username, setUsername, message, setMessage };

	if (!getToken() || getToken() === undefined) {
		return <Login props={props} />
	}

	return (
		<>
			<Router>
				<Routes>
					<Route path="admin">
						<Route path="" element={<Navigate to="alunos" replace={true} />} />
						<Route path="alunos">
							<Route path="" element={<Students props={props} />} />
							<Route path="cadastrar" element={<FormStudent context='create' props={props} />} />
							<Route path=":cpf" element={<Student props={props} />} />
							<Route path=":cpf/editar" element={<FormStudent context='edit' props={props} />} />
							<Route path="*" element={<Navigate to="" replace={true} />} />
						</Route>
						<Route path="empresas">
							<Route path="" element={<Companies props={props} />} />
							<Route path="cadastrar" element={<FormCompany context='create' props={props} />} />
							<Route path=":cnpj" element={<FormCompany context='view' props={props} />} />
							<Route path=":cnpj/editar" element={<FormCompany context='edit' props={props} />} />
						</Route>
						<Route path="*" element={<Navigate to="" replace={true} />} />
					</Route>
					<Route path="">
						<Route path="" element={<Training props={props} />} />
					</Route>
				</Routes>
			</Router>
		</>
	);
}
