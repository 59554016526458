import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { BoxArrowRight } from 'react-bootstrap-icons';
import logo from '../../assets/logo.svg';

export default function Topbar({ clearToken, username }) {
	return (
		<Navbar bg='primary' variant='dark' expand='md'>
			<Container>
				<Navbar.Brand href="/admin">
					<img
						src={logo}
						width='170'
						className='mb-1 pb-1 pt-1'
						alt='Prioriza CT'
					/>{' '}
					<span className='d-inline-block pt-1-'>Admin</span>
				</Navbar.Brand>
				<Navbar.Toggle aria-controls="navbarScroll" />
				<Navbar.Collapse id="navbarScroll">
					<Nav className="me-auto my-2 my-lg-0">
						<Nav.Link href='/admin/alunos'>Alunos</Nav.Link>
						<Nav.Link href='/admin/empresas'>Empresas</Nav.Link>
						{/* <NavDropdown menuVariant='dark' title='Alunos'>
							<NavDropdown.Item href='/admin/alunos'>Listar</NavDropdown.Item>
							<NavDropdown.Item href='/admin/alunos/cadastrar'>Cadastrar</NavDropdown.Item>
						</NavDropdown> */}
						{/* <NavDropdown menuVariant='dark' title='Empresas'>
							<NavDropdown.Item href='/admin/empresas'>Listar</NavDropdown.Item>
							<NavDropdown.Item href='/admin/empresas/cadastrar'>Cadastrar</NavDropdown.Item>
						</NavDropdown> */}
					</Nav>
					<Nav>
						<Nav.Link onClick={clearToken}>
							Sair{' '}
							<BoxArrowRight size={18} className='mb-1' />
						</Nav.Link>
						{/* <NavDropdown menuVariant="dark" drop='down-centered' align="end" title={
							<>
								{username} <PersonCircle size={24} className="mb-1" />
							</>
						}>
							<NavDropdown.Item onClick={clearToken}>Sair</NavDropdown.Item>
						</NavDropdown> */}
					</Nav>
				</Navbar.Collapse>
			</Container>
		</Navbar>
	)
}
