import { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { List } from 'react-bootstrap-icons';
import Navbar from '../../components/NavbarTrainingPage';
import Sidebar from '../../components/Sidebar';
import Lesson from '../../components/Lesson';
import ExamIntro from '../../components/ExamIntro';
import Exam from '../../components/Exam';
import Intro from '../../components/Intro';
import Result from '../../components/Result';
import Certification from '../../components/Certification';
import useData from '../../hooks/useData';
const apiBaseUrl = process.env.REACT_APP_API_URL;
const apiUrlPutCompletedLessons = `${apiBaseUrl}/completedLessons`;
const apiUrlStartExam = `${apiBaseUrl}/startExam`;

export default function Training({ props }) {
	const { getToken, clearToken, message, setMessage, setStatusCode } = props;
	const [data, setData] = useState(null);
	const [actualLessonId, setActualLessonId] = useState(null);
	const [completedLessons, setCompletedLessons] = useState(null);
	const [examIntro, setExamIntro] = useState(false);
	const [exam, setExam] = useState(false);
	const [showIntro, setShowIntro] = useState(true);
	const [showResult, setShowResult] = useState(false);
	const [showCertification, setShowCertification] = useState(false);
	const [showMenu, setShowMenu] = useState(false);

	const {
		loading: loadingData,
		setLoading: setLoadingData,
		error: errorData
	} = useData({
		url: apiBaseUrl,
		token: getToken(),
		setStatusCode,
		setData,
		loadingInit: true
	});

	const {
		loadingSend: loadingCompletedLessons,
		setLoadingSend: setLoadingCompletedLessons,
		// setId: setEnrollmentId,
		error: errorCompletedLessons
	} = useData({
		url: apiUrlPutCompletedLessons,
		token: getToken(),
		method: 'PUT',
		dataToSend: completedLessons,
		setMessage,
		setStatusCode
	});

	const {
		loadingSend: loadingStartExam,
		setLoadingSend: setLoadingStartExam,
		error: errorStartExam
	} = useData({
		url: apiUrlStartExam,
		token: getToken(),
		method: 'PUT',
		setResponseIsOk: setExam,
		setMessage,
		setStatusCode
	});

	const completeLesson = () => {
		const newCompletedLessons = Array.from(new Set(completedLessons).add(actualLessonId));
		setCompletedLessons(newCompletedLessons);
		// setEnrollmentId(data.enrollments[0]._id);
		setLoadingCompletedLessons(true);
	};

	const startExam = () => setLoadingStartExam(true);
	const handleShowResult = () => setShowResult(true);
	const handleHideResult = () => {
		setExamIntro(true);
		setShowResult(false);
	};
	const handleShowMenu = () => setShowMenu(true);
	const handleCloseMenu = () => setShowMenu(false);

	useEffect(() => {
		if (data && !loadingData) {
			// console.log(data);
			const enrollment = data.enrollments[0];
			const { exam } = enrollment;
			const completedLessonsData = Array.from(new Set(enrollment.completedLessons));
			completedLessons === null && setCompletedLessons(completedLessonsData);
			!actualLessonId && setActualLessonId(enrollment.training.lessons[0]._id);
			setExam(exam.active);
			setShowResult(exam.showResult);
			(exam.active || exam.showResult) && setShowIntro(false);
		}
	}, [data]);

	useEffect(() => {
		setActualLessonId(null);
		showResult && setLoadingData(true);
	}, [showResult]);

	if (!data || !completedLessons) {
		return (
			<>
				<Navbar clearToken={clearToken} />
				<Container>
					<div className='d-flex justify-content-center vh-90 align-items-center'>
						<div className='spinner-border'>
						</div>
					</div>
				</Container>
			</>
		)
	}

	const { training, chances } = data.enrollments[0];
	const { name } = data;
	const sidebarProps = {
		name,
		training,
		actualLessonId,
		setActualLessonId,
		completedLessons,
		examIntro,
		setExamIntro,
		clearToken,
		handleCloseMenu,
		setShowIntro
	};

	return (
		<>
			{showCertification ?
				<Certification data={data} props={props} />
				:
				showResult ?
					<Result
						data={data}
						loadingData={loadingData}
						handleHideResult={handleHideResult}
						props={props}
						setShowCertification={setShowCertification}
					/>
					:
					showIntro ?
						<Intro
							data={data}
							props={props}
							setShowIntro={setShowIntro}
						/>
						:
						<Container fluid>
							{
								exam ?
									<Exam setExam={setExam} handleShowResult={handleShowResult} props={props} />
									:
									<Row>
										<Col xl={3} lg={4} md={4} className='d-none d-lg-block g-0'>
											<Sidebar sidebarProps={sidebarProps} />
										</Col>
										<Col className='g-0'>
											<Button
												className='menuBtn d-md-block d-lg-none position-fixed m-1'
												variant='outline-primary'
												onClick={handleShowMenu}
											>
												<List size={28} color='white' className='position-absolute' />
												<List size={28} className='darkIcon' />
											</Button>
											<Offcanvas className='d-md-block d-lg-none' show={showMenu} onHide={handleCloseMenu}>
												<Sidebar sidebarProps={sidebarProps} />
											</Offcanvas>
											{
												examIntro ?
													<ExamIntro
														startExam={startExam}
														loadingStartExam={loadingStartExam}
														chances={chances}
														data={data}
													/>
													:
													<Lesson
														data={data}
														actualLessonId={actualLessonId}
														completedLessons={completedLessons}
														completeLesson={completeLesson}
													/>
											}
										</Col>
									</Row>
							}
						</Container>
			}
		</>
	);
}
