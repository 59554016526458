import { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Navbar from '../../components/NavbarAdmin';
import TableStudents from '../../components/TableStudents';
import ModalDelete from '../../components/ModalDelete';
import useData from '../../hooks/useData';
const apiBaseUrl = process.env.REACT_APP_API_URL;
const apiUrl = `${apiBaseUrl}/admin/alunos`;

export default function Students({ props }) {
	const { token, clearToken, username, message, setMessage, setStatusCode } = props;
	const [students, setStudents] = useState(null);
	const [studentToDelete, setStudentToDelete] = useState(null);
	const [studentName, setStudentName] = useState(null);

	const {
		loading,
		setLoading,
		error
	} = useData({
		url: apiUrl,
		token: token,
		setStatusCode,
		setData: setStudents,
		loadingInit: true
	});

	const {
		setId,
		error: errorDelete
	} = useData({
		url: apiUrl,
		token: token,
		method: 'DELETE',
		setMessage,
		setStatusCode,
		setResponseIsOk: setLoading
	});

	const handleDelete = e => {
		e.preventDefault();
		const student = JSON.parse(e.target.dataset.student);
		setStudentToDelete(student);
		setStudentName(student.name);
	}

	const deleteStudent = () => {
		setId(studentToDelete.cpf);
		setStudentToDelete(null);
	}

	return (
		<>
			<ModalDelete props={{
				itemToDelete: studentToDelete,
				setItemToDelete: setStudentToDelete,
				deleteItem: deleteStudent,
				itemType: 'aluno',
				itemName: studentName
			}} />
			<Navbar clearToken={clearToken} username={username} />
			<Container className='mt-3'>
				{
					message?.success &&
					<Row>
						<Col>
							<Alert variant='success'>
								{message.success}
							</Alert>
						</Col>
					</Row>
				}
				{
					message?.error &&
					<Row>
						<Col>
							<Alert variant='danger'>
								{message.error}
							</Alert>
						</Col>
					</Row>
				}
				{
					errorDelete &&
					<Row >
						<Col>
							<Alert variant="danger">
								{errorDelete}
							</Alert>
						</Col>
					</Row>
				}
				<Row>
					<Col>
						<h1>
							Alunos{' '}
							<Button
								size='sm'
								variant='secondary'
								href='alunos/cadastrar'
								className='mb-1'
							>
								Cadastrar
							</Button>
						</h1>

					</Col>
				</Row>
				<Row className="mt-3">
					<Col>
						{loading
							? 'Carregando...'
							: error
								? <Alert variant='danger' className='mt-4'>{error}</Alert>
								: students.length === 0
									? <p>
										Não há alunos cadastrados.<br />
										<a href='/admin/alunos/cadastrar'>Cadastrar aluno</a>
									</p>
									: <TableStudents props={{ students, handleDelete }} />
						}
					</Col>
				</Row>
			</Container >
		</>
	);
}
