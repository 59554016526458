import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/esm/Row';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Col from 'react-bootstrap/esm/Col';

export default function ExamIntro({ startExam, loadingStartExam, chances }) {

	return (
		<>
			<Container>
				<Row className='mt-5 mt-sm-4 mt-lg-1 px-4 px-sm-2 py-2 pt-4 pt-sm-3'>
					<Col lg={{ span: 11, offset: 0 }} sm={{ span: 11, offset: 1 }}>
						<h2>
							Prova
						</h2>
						<p>
							A prova apresenta <b>30 questões objetivas</b>.
							<br />
							A nota de aprovação é <b>80% (24 acertos)</b>, em até 5 tentativas.
						</p>
						<p>
							A duração máxima da prova é de <b>60 minutos</b>.
							<br />
							Ao iniciar a prova, o tempo começa a ser contado e <b>não pode ser parado</b>.
						</p>
						<p>
							Clique no botão para iniciar a prova.
						</p>
						<p>
							Tentativas restantes: <Badge bg='info'>{chances}</Badge>
						</p>
					</Col>
				</Row>
				<Row className='px-4 px-sm-2'>
					<Col xs={12} lg={{ span: 4, offset: 0 }} sm={{ span: 6, offset: 1 }} className='d-grid'>
						<Button
							// className='mt-5'
							value={loadingStartExam ? 'Iniciando...' : 'Iniciar prova'}
							type='button'
							variant='primary'
							onClick={startExam}
							disabled={loadingStartExam}
						>
							{loadingStartExam
								?
								<span>
									Iniciando{' '}
									<Spinner
										as="span"
										animation="border"
										size="sm"
										role="status"
										aria-hidden="true"
									/>
								</span>
								: 'Iniciar prova'
							}

						</Button>
					</Col>
				</Row>
			</Container>
		</>
	);
}