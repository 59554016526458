import { useState, useEffect } from 'react';
import ReactPlayer from 'react-player/vimeo';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Badge from 'react-bootstrap/Badge';
import { CheckCircle, PlayCircle, PauseCircle } from 'react-bootstrap-icons';

export default function Lesson({
	data,
	actualLessonId,
	completedLessons,
	completeLesson,
}) {
	const isComplete = completedLessons.includes(actualLessonId);
	const { lessons } = data.enrollments[0].training;
	const lesson = lessons.find(lesson => lesson._id === actualLessonId);
	const { videoUrl } = lesson || '';

	const [url, setUrl] = useState(videoUrl);
	const [playerReady, setPlayerReady] = useState(false);
	const [playing, setPlaying] = useState(false);

	const config = {
		vimeo: {
			title: 'Video lesson',
			playerOptions: {
				autoplay: false,
				controls: isComplete
			}
		}
		// youtube: {
		// 	playerVars: {
		// 		// origin: 'http://127.0.0.1:3000',
		// 		showinfo: 0,
		// 		controls: 0,
		// 		disablekb: 1,
		// 		enablejsapi: 1,
		// 		modestbranding: 1,
		// 		autoplay: 0,
		// 		fs: 1,
		// 		rel: 0
		// 	}
		// }
	};

	const handlePlayerReady = () => {
		setPlayerReady(true);
	};

	const handlePlayerClick = () => {
		setPlaying(!playing);
	};

	const handlePlayerContextMenu = e => {
		e.preventDefault();
	};

	const onPlayerEnded = () => {
		!isComplete && completeLesson();
		setUrl(null);
	};

	useEffect(() => {
		if (url === null) {
			setUrl(videoUrl);
			setPlaying(false);
		}
	}, [url]);

	useEffect(() => {
		setUrl(videoUrl);
		setPlayerReady(false);
	}, [lesson]);

	if (!!!url) return '';

	return (
		<>
			<div
				className='playerContainer position-relative pe-auto'
				onClick={handlePlayerClick}
				onContextMenu={handlePlayerContextMenu}
				role='button'
			>
				{!playerReady && !playing &&
					<div className='position-absolute start-50 top-50 translate-middle'>
						<div className='spinner-border'></div>
					</div>
				}
				{playerReady && !playing && !isComplete &&
					<>
						<div className='position-absolute w-100 h-100 bg-black opacity-25'></div>
						<PlayCircle
							size={66}
							color='white'
							className='position-absolute start-50 top-50 translate-middle'
						/>
					</>
				}
				{playerReady && playing &&
					<div className='playerPause position-absolute w-100 h-100 bg-black opacity-0'>
						<PauseCircle
							size={66}
							color='white'
							className='position-absolute start-50 top-50 translate-middle'
						/>
					</div>
				}
				<ReactPlayer
					config={config}
					className='react-player'
					url={url}
					playing={playing}
					onEnded={onPlayerEnded}
					onReady={handlePlayerReady}
				/>
			</div>
			<Container>
				<Row className='mt-2 p-2'>
					<Col>
						<h2>
							{lesson?.title}
						</h2>
						{isComplete &&
							<>
								<Badge bg='info' className='pt-2 mb-2'>
									<CheckCircle size={14} className="mb-1" />
									{' '}Concluído
								</Badge>
							</>
						}
						<p>
							{lesson?.text}
						</p>
					</Col>
				</Row>
			</Container>
		</>
	);
}
