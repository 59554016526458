import { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import Navbar from '../NavbarTrainingPage';
import useData from '../../hooks/useData';
const apiBaseUrl = process.env.REACT_APP_API_URL;
const apiUrlHideResult = `${apiBaseUrl}/hideResult`;

export default function Result({
	props,
	data,
	loadingData,
	handleHideResult,
	setShowCertification
}) {
	const {
		getToken,
		clearToken,
		message,
		setMessage,
		setStatusCode
	} = props;
	// const [data, setData] = useState({});

	// const {
	// 	loading: loadingData,
	// 	error: errorData
	// } = useData({
	// 	url: apiBaseUrl,
	// 	token: getToken(),
	// 	setStatusCode,
	// 	setData,
	// 	loadingInit: true
	// });

	const {
		loadingSend: loadingHideResult,
		setLoadingSend: setHideResult,
		error: errorFinishExam,
		responseOk
	} = useData({
		url: apiUrlHideResult,
		token: getToken(),
		method: 'PUT',
		setMessage,
		setStatusCode
	});

	useEffect(() => {
		responseOk && handleHideResult();
	}, [responseOk]);

	const { chances, grade, approved } = data.enrollments[0];
	const { name } = data;

	const handleClick = () => {
		approved ? setShowCertification(true)
			: chances ? setHideResult(true)
				: clearToken();
	};

	return (
		<>
			<Navbar clearToken={clearToken} />
			{
				(!!!Object.keys(data).length) ?
					<Container>
						<div className='d-flex justify-content-center vh-90 align-items-center'>
							<div className='spinner-border'>
							</div>
						</div>
					</Container>
					:
					<Container>
						<Row className='px-3- pt-3'>
							{/* <Col md={{ span: 6, offset: 3 }} xs={12}> */}
							<Col xs={12}>
								<h4 className='d-flex'>
									Resultado
								</h4>
								<p>
									Nota: {grade}%
									<br />
									Situação: {approved ? 'Aprovado' : 'Reprovado'}
								</p>
								{approved
									?
									<p>
										Parabéns {name.substring(0, name.indexOf(' ')) || name}!
										<br />
										Você concluiu o treinamento com sucesso!
										<br /><br />
										Clique no botão abaixo para ver seu certificado.
									</p>
									:
									chances
										?
										<p>
											Tentativas restantes: <Badge bg='info'>{chances}</Badge>
										</p>
										:
										<p>
											Não há mais tentativas restantes.
											<br />
											Prepare-se melhor na próxima vez!
										</p>
								}
							</Col>
						</Row>
						<Row className='px-3- py-1'>
							{/* <Col md={{ span: 3, offset: 3 }} sm={6} xs={12} className='d-grid gap-2'> */}
							<Col md={3} sm={6} xs={12} className='d-grid gap-2'>
								<Button
									className='d-flex align-items-center justify-content-center'
									type='button'
									variant='primary'
									onClick={handleClick}
									disabled={loadingHideResult}
								>
									<span className='flex-grow-1'>
										{
											loadingHideResult ? 'Carregando...'
												: approved ? 'Ver certificado'
													: chances ? 'Voltar ao treinamento'
														: 'Sair'}
									</span>
									{loadingHideResult && <div className='spinner-border spinner-border-sm'></div>}
								</Button>
							</Col>
						</Row>
					</Container>
			}
		</>
	);
}
