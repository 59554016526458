import { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Nav from 'react-bootstrap/Nav';
import Navbar from '../NavbarAdmin';
import ModalDelete from '../ModalDelete';
import useData from '../../hooks/useData';
const apiBaseUrl = process.env.REACT_APP_API_URL;
const apiUrlGetCompanies = `${apiBaseUrl}/admin/empresas`;
const apiUrl = `${apiBaseUrl}/admin/alunos`;
const apiUrlPost = `${apiUrl}/cadastrar`;

export default function StudentForm({ props, context }) {
	const { token, clearToken, username, setMessage, setStatusCode } = props;
	const { cpf } = useParams();
	const apiUrlStudent = `${apiUrl}/${cpf}`;

	const navigate = useNavigate();
	const [validated, setValidated] = useState(false);
	const [formData, setFormData] = useState({ name: '', cpf: '', company: '' });
	const [postData, setPostData] = useState([]);
	const [companies, setCompanies] = useState(null);
	const [studentToDelete, setStudentToDelete] = useState(null);
	const [createSolo, setCreateSolo] = useState(true);

	const {
		loading: loadingCompanies,
		error: errorCompanies
	} = useData({
		url: apiUrlGetCompanies,
		token: token,
		setStatusCode,
		setData: setCompanies,
		loadingInit: true
	});

	const {
		loadingSend: loadingSendCreate,
		setLoadingSend: setLoadingSendCreate,
		error: errorCreate
	} = useData({
		url: apiUrlPost,
		token: token,
		method: 'POST',
		dataToSend: postData,
		navigateTo: '/admin/alunos',
		setMessage,
		setStatusCode
	});

	const filterFormData = data => setFormData({
		name: data.name,
		cpf: data.cpf,
		company: data.company?._id
	});

	const {
		loading: loadingStudent,
	} = useData({
		url: apiUrlStudent,
		token: token,
		setStatusCode,
		setData: filterFormData,
		loadingInit: context === 'edit'
	});

	const {
		loadingSend: loadingSendEdit,
		setLoadingSend: setLoadingSendEdit,
		error: errorEdit
	} = useData({
		url: apiUrlStudent,
		token: token,
		method: 'PUT',
		dataToSend: formData,
		navigateTo: '/admin/alunos',
		setMessage,
		setStatusCode
	});

	const {
		setId,
	} = useData({
		url: apiUrl,
		token: token,
		method: 'DELETE',
		setMessage,
		setStatusCode,
		navigateTo: '/admin/alunos'
	});

	const handleDelete = e => {
		e.preventDefault();
		setStudentToDelete(formData);
	}

	const deleteStudent = () => {
		setId(formData.cpf);
		setStudentToDelete(null);
	}

	const handleInputChange = e => {
		const { name, value } = e.target;
		setFormData(prevForm => ({ ...prevForm, [name]: value }));
		setValidated(false);
	};

	const handleNavSelect = e => {
		setCreateSolo(e === 'solo');
	};

	const handleSubmit = async e => {
		e.preventDefault();
		const { name, cpf, company, namesAndCpfs } = formData;

		if (createSolo) {
			const requiredFields = [
				'name',
				'cpf',
				'company'
			];
			if (!requiredFields.every(field => formData[field])) {
				return setValidated(true);
			}

			const cpfNum = cpf.replace(/\D/g, '');
			setFormData({ name, cpf: cpfNum, company });
			setPostData([formData]);
		}

		else {
			const requiredFields = [
				'namesAndCpfs',
				'company'
			];
			if (!requiredFields.every(field => formData[field])) {
				return setValidated(true);
			}

			// const students = namesAndCpfs.split('\n').map(line => {
			// 	const [nameSent, cpfSent] = line.split('\t');
			// 	const name = nameSent.trim();
			// 	const cpf = cpfSent.replace(/\D/g, '');
			// 	return { name, cpf, company };
			// });

			const students = namesAndCpfs.split('\n').map(line => {
				let parts = line.split('\t');
				if (parts.length === 1) parts = parts[0].split(' ');
				const cpfPart = parts.pop();
				const name = parts.join(' ');
				const cpf = cpfPart.replace(/\D/g, '');
				return { name, cpf, company };
			});
			console.log(students);
			setPostData(students);
		}

		context === 'create' ?
			setLoadingSendCreate(true)
			: setLoadingSendEdit(true);
	};

	if (context !== 'create' && loadingStudent) {
		return false;
	}

	return (
		<>
			<ModalDelete props={{
				itemToDelete: studentToDelete,
				setItemToDelete: setStudentToDelete,
				deleteItem: deleteStudent,
				itemType: 'aluno',
				itemName: formData.name
			}} />
			<Navbar clearToken={clearToken} username={username} />
			<Container className='mt-3'>
				<Row>
					<Col lg={6} md={8} sm={12} className='d-flex justify-content-between align-items-center'>
						<h1>
							{context === 'create' ? 'Cadastrar ' : 'Editar '}
							aluno
						</h1>
						{context === 'edit' &&
							<Button
								size='sm'
								variant='danger'
								type='button'
								onClick={handleDelete}
								className='text-white'
							>
								Remover
							</Button>
						}
					</Col>
				</Row>
				<Form noValidate validated={validated} onSubmit={handleSubmit}>

					{context === 'create' &&
						<Nav fill as={Col} lg={6} md={8} sm={12} variant='tabs' defaultActiveKey='solo' onSelect={handleNavSelect}>
							<Nav.Item>
								<Nav.Link eventKey='solo'>Individual</Nav.Link>
							</Nav.Item>
							<Nav.Item>
								<Nav.Link eventKey='group'>Grupo</Nav.Link>
							</Nav.Item>
						</Nav>
					}

					{createSolo ?
						<>
							<Row className='mt-3'>
								<Form.Group as={Col} lg={6} md={8} sm={12} className='mb-3' controlId='name'>
									<Form.Label>Nome completo</Form.Label>
									<Form.Control
										required={createSolo}
										type='text'
										placeholder='Digite o nome completo'
										name='name'
										value={formData.name}
										onChange={handleInputChange}
									/>
								</Form.Group>
							</Row>
							<Row>
								<Form.Group as={Col} lg={6} md={8} sm={12} className='mb-3' controlId='cpf'>
									<Form.Label>CPF</Form.Label>
									<Form.Control
										required={createSolo}
										type='text'
										placeholder='Digite o CPF'
										name='cpf'
										value={formData.cpf}
										onChange={handleInputChange}
										disabled={context === 'edit'}
									/>
								</Form.Group>
							</Row>
						</>
						:
						<Row className='mt-3'>
							<Form.Group as={Col} lg={6} md={8} sm={12} className='mb-3' controlId='cpf'>
								<Form.Label>Nomes e CPFs</Form.Label>
								<textarea
									className='form-control'
									required={!createSolo}
									name='namesAndCpfs'
									value={formData.namesAndCpfs || ''}
									rows={20}
									onChange={handleInputChange}
									placeholder='João Silva 12345678901&#10;Maria da Graça 23456789012' />
							</Form.Group>
						</Row>
					}
					<Row>
						<Form.Group as={Col} lg={6} md={8} sm={12} className='mb-4' controlId='company'>
							<Form.Label>Empresa</Form.Label>
							<Form.Select
								required
								name='company'
								onChange={handleInputChange}
								aria-label='Selecione a empresa'
								disabled={errorCompanies && 'disabled'}
								value={formData.company || ''}
							>
								{
									!loadingCompanies && errorCompanies
										? <option disabled>Erro ao carregar empresas</option>
										: <option value=''>Selecione a empresa</option>
								}
								{
									loadingCompanies
										? <option value=''>Carregando empresas...</option>
										: !errorCompanies && companies.map(company =>
											<option key={company._id} value={company._id}>{company.company_name}</option>
										)
								}
							</Form.Select>
						</Form.Group>
						<Col></Col>
					</Row>
					<Row>
						<Col sm={6} md={4} lg={3} className='d-grid'>
							<Button
								md={3} sm={6}
								variant='secondary'
								type='button'
								onClick={() => navigate(-1)}
							>
								Cancelar
							</Button>
						</Col>
						<Col sm={6} md={4} lg={3} className='d-grid mt-2 mt-sm-0'>
							<Button
								variant='primary'
								type='submit'
								disabled={loadingSendCreate || loadingSendEdit}
								onClick={handleSubmit}
							>
								{
									context === 'create' ?
										loadingSendCreate ? 'Cadastrando...' : 'Cadastrar'
										: loadingSendEdit ? 'Salvando...' : 'Salvar'
								}
							</Button>
						</Col>
					</Row>
				</Form>
				{
					(errorCreate || errorEdit) &&
					<Alert variant='danger' className='mt-4'>
						{errorCreate || errorEdit}
					</Alert>
				}
			</Container >
		</>
	);
}
