import { useState } from 'react';
import { useParams } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Navbar from '../../components/NavbarAdmin';
import useData from '../../hooks/useData';
const apiBaseUrl = process.env.REACT_APP_API_URL;

export default function Student({ props }) {
	const { token, clearToken, username, message, setMessage, setStatusCode } = props;
	let { cpf } = useParams();
	const apiUrl = `${apiBaseUrl}/admin/alunos/${cpf}`;
	const apiUrlEnroll = `${apiBaseUrl}/admin/alunos/${cpf}/enroll`;
	const [student, setStudent] = useState(null);

	const {
		setLoading: setLoadingGet,
		loading,
		error
	} = useData({
		url: apiUrl,
		token: token,
		setStatusCode,
		setData: setStudent,
		loadingInit: true
	});

	const {
		loadingSend: loadingSendEdit,
		setLoadingSend: setEnroll,
		error: errorEnroll
	} = useData({
		url: apiUrlEnroll,
		token: token,
		method: 'PUT',
		setMessage,
		setStatusCode,
		setSendSuccess: setLoadingGet
	});

	const handleEnroll = () => {
		setEnroll(true);
	};

	return (
		<>
			<Navbar clearToken={clearToken} username={username} />
			<Container className='mt-3'>
				{message &&
					<Row>
						<Col>
							<Alert variant='success'>
								{message}
							</Alert>
						</Col>
					</Row>
				}
				{errorEnroll &&
					<Row>
						<Col>
							<Alert variant='danger'>
								{errorEnroll}
							</Alert>
						</Col>
					</Row>
				}
				<Row>
					<Col>
						<h1>
							Aluno{' '}
							<Button
								size='sm'
								variant='secondary'
								href={`${cpf}/editar`}
								className='mb-1'
							>
								Editar
							</Button>
						</h1>
					</Col>
				</Row>
				{loading
					? 'Carregando...'
					: error
						? <Alert variant='danger' className='mt-4'>{error}</Alert>
						:
						<Row>
							<Col>
								<p>
									Nome: {student.name}<br />
									CPF: {student.cpf}<br />
									Empresa: {' '}
									<a href={'/admin/empresas/' + student?.company?.cnpj}>
										{student?.company?.company_name}
									</a>
								</p>
								<h4>
									Histórico{' '}
									<Button
										size='sm'
										variant='secondary'
										className='mb-1'
										onClick={handleEnroll}
										disabled={
											!student.enrollments[0].overdue
											&& student.enrollments[0].status === 'active'
										}
									>
										Renovar matrícula
									</Button>
								</h4>
								{student.enrollments.map((enrollment, i) =>
									<p key={i}>
										Treinamento: {enrollment.training?.title}
										<br />
										Matrícula: {new Date(enrollment.createdAt)
											.toLocaleDateString('pt-BR', {
												day: '2-digit', month: '2-digit', year: 'numeric'
											})}
										<br />
										{enrollment.grade !== undefined &&
											<>
												Nota: {enrollment.grade}%<br />
											</>
										}
										Status: {enrollment.statusVirtual}
										<br />
										{enrollment.approved &&
											<>
												Data da aprovação: {new Date(enrollment.exam.createdAt)
													.toLocaleDateString('pt-BR', {
														day: '2-digit', month: '2-digit', year: 'numeric'
													})}
											</>
										}
									</p>
								)}
							</Col>
						</Row>
				}
				<Row className='mt-3'>
					<Col>
						<a href='/admin/alunos'>Voltar para alunos</a>
					</Col>
				</Row>
			</Container >
		</>
	);
}
