import { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

export default function ModalDelete({ props }) {
	const { itemToDelete, setItemToDelete, deleteItem, itemType, itemName } = props;
	const [showModal, setShowModal] = useState(false);

	// const handleCloseModal = () => setShowModal(false);
	const handleCloseModal = () => setItemToDelete(null);

	useEffect(() => {
		itemToDelete ? setShowModal(true) : setShowModal(false);
	}, [itemToDelete]);

	return (
		<Modal
			show={showModal}
			onHide={handleCloseModal}
		>
			<Modal.Header closeButton>
				<Modal.Title>Remover {itemType}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				Deseja remover {itemName}?
			</Modal.Body>
			<Modal.Footer>
				<Button variant='secondary' onClick={handleCloseModal}>
					Cancelar
				</Button>
				<Button variant='danger' className='text-white' onClick={deleteItem}>
					Remover
				</Button>
			</Modal.Footer>
		</Modal>
	);
}
