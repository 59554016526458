import { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import Navbar from '../NavbarTrainingPage';
import useData from '../../hooks/useData';
const apiBaseUrl = process.env.REACT_APP_API_URL;
const apiUrlHideResult = `${apiBaseUrl}/hideResult`;

export default function Intro({ props, data, setShowIntro }) {
	const {
		getToken,
		clearToken,
		message,
		setMessage,
		setStatusCode
	} = props;

	if (Object.keys(data).length === 0) {
		return <>Carregando...</>;
	}
	const { name } = data;
	const firstName = name.substring(0, name.indexOf(' ')) || name;
	const { dueDateFormatted } = data.enrollments[0];
	const { title } = data.enrollments[0].training;

	const handleClick = () => {
		setShowIntro(false);
	};

	return (
		<>
			<Navbar clearToken={clearToken} />
			<Container>
				<Row className='px-3- pt-3'>
					<Col xs={12}>
						<h3 className='d-flex'>
							Olá, {firstName}
						</h3>
						<h4 className='d-flex'>
							Bem vindo(a) à PRIORIZA Centro de Treinamentos.
						</h4>
						<p>
							Você está matriculado(a) no treinamento:
						</p>
						<p>
							<strong>{title}</strong>
						</p>
						<p>
							O prazo para conclusão é {dueDateFormatted}.
						</p>
					</Col>
				</Row>
				<Row className='px-3- py-1'>
					<Col md={3} sm={6} xs={12} className='d-grid gap-2'>
						<Button
							className='d-flex align-items-center justify-content-center text-nowrap'
							type='button'
							variant='primary'
							onClick={handleClick}
						>
							<span className='flex-grow-1'>
								Acessar treinamento
							</span>
						</Button>
					</Col>
				</Row>
			</Container>
		</>
	);
}
