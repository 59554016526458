import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default function useData(props) {
	const { url,
		urlGet,
		urlSend,
		token,
		method,
		loadingInit,
		setData,
		render,
		dataToSend,
		setResponseIsOk,
		setMessage,
		setStatusCode,
		navigateTo,
		setSendSuccess
	} = props;
	const navigate = useNavigate();
	const [loading, setLoading] = useState(loadingInit);
	const [loadingSend, setLoadingSend] = useState(false);
	const [id, setId] = useState(null);
	const [error, setError] = useState(null);
	const [responseOk, setResponseOk] = useState(null);

	const getData = async () => {
		setError(null);

		try {
			const headers = { token: token };
			const response = await fetch(url, { headers });
			const jsonData = await response.json();

			if (!response.ok) {
				setStatusCode(response.status);
				throw new Error(jsonData.message);
			}

			setData(jsonData);
		} catch (err) {
			setError(err.message);
		}
		setLoading(false);
	};

	useEffect(() => {
		loading && getData();
	}, [loading]);

	const sendData = async () => {
		// console.log('dataToSend');
		// console.log(dataToSend);
		try {
			const response = await fetch(
				id ? `${url}/${id}` : url,
				{
					method,
					headers: {
						'Content-Type': 'application/json',
						'token': token
					},
					...(dataToSend ? { body: JSON.stringify(dataToSend) } : {})
				}
			);
			const jsonData = await response.json();
			setResponseIsOk && setResponseIsOk(response.ok);
			setResponseOk(response.ok);
			setData && setData(jsonData);
			// console.log('res jsonData');
			// console.log(jsonData);

			if (!response.ok) {
				setStatusCode(response.status);
				throw new Error(jsonData.message);
			}

			setMessage && setMessage(jsonData.message);
			navigateTo && navigate(navigateTo);
			render && setLoading(true);
			setSendSuccess && setSendSuccess(true);

		} catch (err) {
			setError(err.message);
		}
		setId(null);
		setLoadingSend(false);
		// console.log('send end');
	};

	useEffect(() => {
		if (loadingSend || id) {
			sendData();
		}
	}, [loadingSend, id]);

	return {
		// getData,
		// sendData,
		// fetchData,
		responseOk,
		loadingSend,
		setLoadingSend,
		// loadingGet,
		// setLoadingGet,
		loading,
		setLoading,
		// id,
		setId,
		// updateId,
		// data,
		// loading,
		error
	}
}
