import Table from 'react-bootstrap/Table';
import Dropdown from 'react-bootstrap/Dropdown';

export default function TableCompanies({ props }) {
	const { companies, handleDelete } = props;
	return (
		<Table striped bordered hover>
			<thead>
				<tr>
					<th>Nome</th>
					<th>CNPJ</th>
					<th>Contato</th>
					<th>Fone</th>
					<th>Email</th>
				</tr>
			</thead>
			<tbody>
				{companies.map(company =>
					<tr key={company._id}>
						<td>
							<a href={`empresas/${company.cnpj}`}>{company.company_name}</a>
							{/* <Dropdown drop="end">
								<Dropdown.Toggle variant="link">
									{company.company_name}
								</Dropdown.Toggle>
								<Dropdown.Menu variant="dark">
									<Dropdown.Item href={`empresas/${company.cnpj}`}>Ver</Dropdown.Item>
									<Dropdown.Item href={`empresas/${company.cnpj}/editar`}>Editar</Dropdown.Item>
									<Dropdown.Item data-company={JSON.stringify(company)} onClick={handleDelete}>Remover</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown> */}
						</td>
						<td>{company.cnpj}</td>
						<td>{company.contact_name}</td>
						<td>{company.phone}</td>
						<td>{company.email}</td>
					</tr>
				)}
			</tbody>
		</Table>
	)
}
