import { useState, useEffect, useRef } from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Badge from 'react-bootstrap/Badge';
import { LockFill, UnlockFill, CheckCircle, Circle, PersonFill } from 'react-bootstrap-icons';
import NavbarTraining from '../NavbarTraining';

export default function Sidebar({ sidebarProps }) {
	const {
		name,
		training,
		actualLessonId,
		setActualLessonId,
		completedLessons,
		examIntro,
		setExamIntro,
		handleCloseMenu,
		clearToken,
		setShowIntro
	} = sidebarProps;
	const trainingTitle = useRef(null);
	const [titleHeight, setTitleHeight] = useState(null);
	const completedAll = completedLessons.length >= training.lessons.length;

	useEffect(() => {
		setTitleHeight(trainingTitle.current.offsetHeight);
	}, []);

	const handleClickLesson = e => {
		e.preventDefault();
		setExamIntro(false);
		setActualLessonId(e.target.dataset.id);
		handleCloseMenu && handleCloseMenu();
	};

	const handleClickExam = e => {
		e.preventDefault();
		setActualLessonId(null);
		setExamIntro(true);
		handleCloseMenu && handleCloseMenu();
	};

	return (
		<div className='sidebar overflow-scroll'>
			<NavbarTraining setShowIntro={setShowIntro} clearToken={clearToken} />
			<div className='trainingTitle sticky-top bg-white pt-2'>
				<h5 ref={trainingTitle} className='mx-1 px-2'>{training.title}</h5>
				<ProgressBar
					className='ProgressBar'
					variant='info'
					now={completedLessons.length / training.lessons.length * 100}
				/>
			</div>
			<ListGroup variant='flush'>
				<ListGroup.Item
					onClick={handleClickExam}
					active={examIntro}
					disabled={!completedAll}
					action
					variant='primary'
					className='d-flex align-items-center position-sticky- sticky-top top-5 px-2'
					style={{ top: titleHeight + 78 + 'px' }}
				>
					<div className='pe-2'>
						{completedAll
							? <UnlockFill size={18} className="ms-1 mb-1" />
							: <LockFill size={18} className="ms-1 mb-1" />
						}
					</div>
					<div className='mx-1-'>
						Prova
					</div>
					<div className='d-flex ms-auto pe-2'>
						{completedAll
							? <Badge bg="info">Disponível</Badge>
							: (
								<Badge bg="secondary" text="dark">
									{completedLessons.length}/{training.lessons.length} aulas concluídas
								</Badge>
							)}
					</div>
				</ListGroup.Item>
				{
					training.lessons.map((lesson, i) =>
						<ListGroup.Item
							key={lesson._id}
							data-id={lesson._id}
							onClick={handleClickLesson}
							active={lesson._id === actualLessonId}
							action
							variant='primary'
							className='d-flex px-2'
							disabled={completedLessons.length < i}
						>
							<div className='pe-none pe-2'>
								{
									completedLessons.length < i
										? <LockFill size={18} className="ms-1 mb-1" />
										: completedLessons.includes(lesson._id)
											? <CheckCircle size={18} className="ms-1 mb-1" />
											: <Circle size={18} className="ms-1 mb-1" />
								}
							</div>
							<div className='pe-none'>
								{lesson.title}
							</div>
						</ListGroup.Item>
					)
				}

				<ListGroup.Item className='d-flex align-items-center sticky-bottom px-2'>
					<div className='pe-2'>
						<PersonFill size={18} className="ms-1 mb-1" />
					</div>
					<div className='mx-1-'>
						{name}
					</div>
				</ListGroup.Item>

			</ListGroup>
		</div>
	);
}
