import { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import useData from '../../hooks/useData';
import Navbar from '../../components/NavbarAdmin';
import TableCompanies from '../../components/TableCompanies';
import ModalDelete from '../../components/ModalDelete';
const apiBaseUrl = process.env.REACT_APP_API_URL;
const apiUrl = `${apiBaseUrl}/admin/empresas`;

export default function Companies({ props }) {
	const { token, clearToken, username, message, setMessage, setStatusCode } = props;
	const [companies, setCompanies] = useState(null);
	const [companyToDelete, setCompanyToDelete] = useState(null);
	const [companyName, setCompanyName] = useState(null);

	const {
		loading,
		setLoading,
		error
	} = useData({
		url: apiUrl,
		token: token,
		setStatusCode,
		setData: setCompanies,
		loadingInit: true
	});

	const {
		setId: setDeleteId,
		error: errorDelete
	} = useData({
		url: apiUrl,
		token: token,
		method: 'DELETE',
		setMessage,
		setStatusCode,
		setResponseIsOk: setLoading
	});

	const handleDelete = e => {
		e.preventDefault();
		const company = JSON.parse(e.target.dataset.company);
		setCompanyToDelete(company);
		setCompanyName(company.company_name);
	}

	const deleteCompany = () => {
		setDeleteId(companyToDelete._id);
		setCompanyToDelete(null);
	}

	return (
		<>
			<ModalDelete props={{
				itemToDelete: companyToDelete,
				setItemToDelete: setCompanyToDelete,
				deleteItem: deleteCompany,
				itemType: 'empresa',
				itemName: companyName
			}} />
			<Navbar clearToken={clearToken} username={username} />
			<Container className="mt-3">
				{
					message &&
					<Row>
						<Col>
							<Alert variant="success">
								{message}
							</Alert>
						</Col>
					</Row>
				}
				{
					errorDelete &&
					<Row >
						<Col>
							<Alert variant="danger">
								{errorDelete}
							</Alert>
						</Col>
					</Row>
				}
				<Row>
					<Col>
						<h1>
							Empresas{' '}
							<Button
								size='sm'
								variant='secondary'
								href='empresas/cadastrar'
							>
								Cadastrar
							</Button>
						</h1>
					</Col>
				</Row>
				<Row className="mt-3">
					<Col>
						{loading
							? 'Carregando...'
							: error
								? <Alert variant='danger' className='mt-4'>{error}</Alert>
								: companies.length === 0
									? <p>
										Não há empresas cadastradas.<br />
										<a href='/admin/empresas/cadastrar'>Cadastrar empresa</a>
									</p>
									: <TableCompanies props={{ companies, handleDelete }} />

						}
					</Col>
				</Row>
			</Container >
		</>
	);
}
