import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { BoxArrowRight } from 'react-bootstrap-icons';

export default function Topbar({ clearToken, username }) {
	return (
		<Navbar sticky='top' bg='primary' variant='dark' expand={true}>
			<Container>
				<Navbar.Brand>
					<img
						src='logo.svg'
						width='170'
						className='mb-1'
						alt='Prioriza CT'
					/>
				</Navbar.Brand>
				<Navbar.Toggle aria-controls='navbarScroll' />
				<Navbar.Collapse id='navbarScroll'>
					<Nav className='me-auto my-2 my-lg-0'>
					</Nav>
					<Nav>
						<Nav.Link onClick={clearToken}>
							Sair{' '}
							<BoxArrowRight size={18} className='mb-1' />
						</Nav.Link>
					</Nav>
				</Navbar.Collapse>
			</Container>
		</Navbar>
	)
}
