import { useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Badge from 'react-bootstrap/Badge';
import Stack from 'react-bootstrap/Stack';
import { Stopwatch, ChevronLeft, ChevronRight } from 'react-bootstrap-icons';
import ToggleButton from 'react-bootstrap/ToggleButton';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import useData from '../../hooks/useData';
const apiBaseUrl = process.env.REACT_APP_API_URL;
const apiUrlGetExam = `${apiBaseUrl}/exam`;
const apiUrlPutAnswers = `${apiBaseUrl}/answers`;
const apiUrlFinishExam = `${apiBaseUrl}/finishExam`;

const Timer = ({ createdAt, setOverdue }) => {
	// const duration = 10 * 1000; // 10 seconds
	const duration = 60 * 60 * 1000; // 60 minutes
	const [remainingTime, setRemainingTime] = useState(1);

	useEffect(() => {
		remainingTime === 0 && setOverdue(true);
	}, [remainingTime]);

	useEffect(() => {
		const interval = setInterval(() => {
			const currentTime = new Date().getTime();
			const targetTime = new Date(createdAt).getTime() + duration; // createdAt + 10 seconds
			const timeDifference = targetTime - currentTime;

			if (timeDifference <= 0) {
				clearInterval(interval);
				setRemainingTime(0);
			} else {
				setRemainingTime(timeDifference);
			}
		}, 1000);

		return () => {
			clearInterval(interval);
		};
	}, [createdAt]);

	const seconds = Math.floor(remainingTime / 1000);

	const formatTime = time => {
		const minutes = Math.floor(time / 60);
		const seconds = time % 60;
		return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
	};

	return (
		<span className='ms-1'>
			{formatTime(seconds)}
		</span>
	);
};

export default function Exam({ props, setExam, handleShowResult }) {
	const { getToken, message, setMessage, setStatusCode } = props;
	const [data, setData] = useState(null);
	const [questions, setQuestions] = useState(null);
	const [questionIndex, setQuestionIndex] = useState(0);
	const [answers, setAnswers] = useState({});
	const [overdue, setOverdue] = useState(false);
	const isFirst = questionIndex === 0;
	const isLast = questionIndex + 1 === questions?.length;

	const {
		loading: loadingData,
		error: errorData
	} = useData({
		url: apiUrlGetExam,
		token: getToken(),
		setStatusCode,
		setData,
		loadingInit: true
	});

	const {
		loadingSend,
		setLoadingSend: setLoadingSendAnswers,
		error
	} = useData({
		url: apiUrlPutAnswers,
		token: getToken(),
		method: 'PUT',
		dataToSend: answers,
		setData: setOverdue,
		setMessage,
		setStatusCode
	});

	const {
		loadingSend: loadingFinishExam,
		setLoadingSend: setLoadingFinishExam,
		error: errorFinishExam,
		responseOk: responseOkFinishExam
	} = useData({
		url: apiUrlFinishExam,
		token: getToken(),
		method: 'PUT',
		setMessage,
		setStatusCode
	});


	useEffect(() => {
		if (data && !loadingData) {
			// console.log(data.enrollments[0]);
			// console.log(data.enrollments[0].exam.createdAt);
			// setQuestions(data.enrollments[0].training.questions);
			setQuestions(data.enrollments[0].exam.questions);
			setAnswers(data.enrollments[0].exam.answers || {});
		}
	}, [data]);

	useEffect(() => {
		data && !loadingData && setLoadingSendAnswers(true);
	}, [answers]);

	useEffect(() => {
		overdue && setLoadingFinishExam(true);
	}, [overdue]);

	useEffect(() => {
		responseOkFinishExam && setExam(false);
		responseOkFinishExam && handleShowResult();
	}, [responseOkFinishExam]);

	const handleChange = e => setAnswers(prev => ({
		...prev,
		[questionIndex]: {
			questionId: questions[questionIndex]._id,
			optionId: e.target.id
		}
	}));

	const handleClickPrev = () => setQuestionIndex(questionIndex - 1);
	const handleClickNext = () => setQuestionIndex(questionIndex + 1);
	const handleClickFinish = () => setLoadingFinishExam(true);

	return (
		!questions ?
			<div className='d-flex justify-content-center vh-90 align-items-center'>
				<div className='spinner-border'>
				</div>
			</div>
			: (
				<>
					<Row className='px-2 py-3'>
						<Col xl={{ span: 6, offset: 3 }} md={{ span: 8, offset: 2 }} xs={12}>
							<h4 className='d-flex'>
								<span>Prova: {data.enrollments[0].training.title}</span>

								<Badge bg='secondary' text='dark' className='d-flex ms-auto align-items-center ps-2'>
									<Stopwatch size={18} />{' '}
									{/* <span className='ms-1'>59:31</span> */}
									<Timer createdAt={data.enrollments[0].exam.createdAt} setOverdue={setOverdue} />
								</Badge>
							</h4>
							<p>
								Questão {questions && `${questionIndex + 1}/${questions.length}`}
							</p>
							<h5 className='pb-2'>
								{questions && questions[questionIndex].statement}
							</h5>
							<Stack gap={3}>
								{questions && questions[questionIndex].options.map(option => (
									<ToggleButton
										key={option._id}
										id={option._id}
										type='radio'
										variant='outline-primary'
										className='text-start'
										name='option'
										checked={answers[questionIndex]?.optionId === option._id}
										onChange={handleChange}
									>
										{option.text}
									</ToggleButton>
								))}
							</Stack>
						</Col>
					</Row>
					<Row className='px-2 py-3'>
						<Col xl={{ span: 3, offset: 3 }} md={{ span: 4, offset: 2 }} xs={6} className='d-grid gap-2'>
							{!isFirst &&
								<Button
									className='d-flex ms-auto- align-items-center justify-content-center'
									value='Anterior'
									type='button'
									variant='secondary'
									onClick={handleClickPrev}
									disabled={questionIndex === 0}
								>
									<ChevronLeft size={16} />
									<span className='flex-grow-1'>
										Anterior
									</span>
								</Button>
							}
						</Col>
						<Col xl={{ span: 3, offset: 0 }} md={{ span: 4, offset: 0 }} xs={6} className='d-grid gap-2'>
							<Button
								type='button'
								className='d-flex ms-auto- align-items-center justify-content-center'
								variant={isLast ? 'success' : 'primary'}
								onClick={isLast ? handleClickFinish : handleClickNext}
								disabled={!answers[questionIndex] || loadingFinishExam}
							>
								<span className='flex-grow-1'>
									{!isLast ? 'Próxima' : loadingFinishExam ? 'Finalizando ' : 'Finalizar'}
								</span>
								{
									!isLast
										? <ChevronRight size={16} />
										: loadingFinishExam &&
										<Spinner
											as="span"
											animation="border"
											size="sm"
											role="status"
											aria-hidden="true"
										/>
								}
							</Button>
						</Col>
					</Row>
				</>
			)
	);
}
