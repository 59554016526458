import { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Navbar from '../NavbarAdmin';
import ModalDelete from '../ModalDelete';
import useData from '../../hooks/useData';
const apiBaseUrl = process.env.REACT_APP_API_URL;
const apiUrl = `${apiBaseUrl}/admin/empresas`;
const apiUrlPostCompany = `${apiUrl}/cadastrar`;

export default function CompanyForm({ props, context }) {
	const { token, clearToken, username, setMessage, setStatusCode } = props;
	const { cnpj } = useParams();
	const apiUrlCompany = `${apiUrl}/${cnpj}`;
	const disabled = context === 'view';

	const navigate = useNavigate();
	const [validated, setValidated] = useState(false);
	const [formData, setFormData] = useState({
		company_name: '',
		cnpj: '',
		contact_name: '',
		email: '',
		phone: ''
	});
	const [companyToDelete, setCompanyToDelete] = useState(null);

	const {
		loadingSend: loadingSendCreate,
		setLoadingSend: setLoadingSendCreate,
		error
	} = useData({
		url: apiUrlPostCompany,
		token: token,
		method: 'POST',
		dataToSend: formData,
		navigateTo: '/admin/empresas',
		setMessage,
		setStatusCode
	});

	const {
		loading: loadingCompany,
		error: errorLoadingCompany
	} = useData({
		url: apiUrlCompany,
		token: token,
		setStatusCode,
		setData: setFormData,
		loadingInit: context !== 'create'
	});

	const {
		loadingSend: loadingSendEdit,
		setLoadingSend: setLoadingSendEdit,
		error: errorEdit
	} = useData({
		url: apiUrlCompany,
		token: token,
		method: 'PUT',
		dataToSend: formData,
		navigateTo: '/admin/empresas',
		setMessage,
		setStatusCode
	});

	const {
		setId: setDeleteId,
		error: errorDelete
	} = useData({
		url: apiUrl,
		token: token,
		method: 'DELETE',
		setMessage,
		setStatusCode,
		navigateTo: '/admin/empresas'
	});

	const handleDelete = e => {
		e.preventDefault();
		setCompanyToDelete(formData);
	}

	const deleteCompany = () => {
		setDeleteId(formData.cnpj);
		setCompanyToDelete(null);
	}

	const handleInputChange = e => {
		const { name, value } = e.target;
		setFormData(prevForm => ({ ...prevForm, [name]: value }));
		setValidated(false);
	};

	const handleSubmit = async e => {
		e.preventDefault();

		const requiredFields = [
			'company_name',
			'cnpj',
			'contact_name',
			'email',
			'phone'
		];
		if (!requiredFields.every(field => formData[field])) {
			return setValidated(true);
		}
		const cnpjNum = formData.cnpj.replace(/\D/g, '');
		setFormData(prevForm => ({ ...prevForm, cnpj: cnpjNum }));

		context === 'create' ?
			setLoadingSendCreate(true)
			: setLoadingSendEdit(true);
	};

	if (context !== 'create' && loadingCompany) {
		return false;
	}

	return (
		<>
			<ModalDelete props={{
				itemToDelete: companyToDelete,
				setItemToDelete: setCompanyToDelete,
				deleteItem: deleteCompany,
				itemType: 'empresa',
				itemName: formData.company_name
			}} />
			<Navbar clearToken={clearToken} username={username} />
			<Container className="mt-3">
				<Row>
					<Col lg={6} md={8} sm={12} className='d-flex justify-content-between align-items-center'>
						<h1>
							{context === 'create' && 'Cadastrar empresa'}
							{context === 'edit' && 'Editar empresa'}
							{context === 'view' && 'Empresa '}
						</h1>
						{context === 'edit' &&
							<Button
								size='sm'
								variant='danger'
								type='button'
								onClick={handleDelete}
								className='text-white'
							>
								Remover
							</Button>
						}
						{context === 'view' &&
							<Button
								size='sm'
								variant='secondary'
								href={`${cnpj}/editar`}
							>
								Editar
							</Button>
						}
					</Col>
				</Row>
				<Form noValidate validated={validated} onSubmit={handleSubmit}>
					<Row className="mt-3">
						<Form.Group as={Col} lg={6} md={8} sm={12} className="mb-3" controlId="company_name">
							<Form.Label>Razão social</Form.Label>
							<Form.Control
								required
								type="text"
								placeholder="Digite a razão social"
								name="company_name"
								value={formData.company_name}
								onChange={handleInputChange}
								readOnly={context === 'view'}
							/>
						</Form.Group>
						<Col></Col>
					</Row>
					<Row>
						<Form.Group as={Col} lg={6} md={8} sm={12} className="mb-3" controlId="cnpj">
							<Form.Label>CNPJ</Form.Label>
							<Form.Control
								required
								type="text"
								placeholder="Digite o CNPJ (apenas números)"
								name="cnpj"
								value={formData.cnpj}
								onChange={handleInputChange}
								disabled={context === 'edit'}
								readOnly={context === 'view'}
							/>
						</Form.Group>
						<Col></Col>
					</Row>
					<Row>
						<Form.Group as={Col} lg={6} md={8} sm={12} className="mb-3" controlId="contact_name">
							<Form.Label>Nome do contato</Form.Label>
							<Form.Control
								required
								type="text"
								placeholder="Digite o nome do contato"
								name="contact_name"
								value={formData.contact_name}
								onChange={handleInputChange}
								readOnly={context === 'view'}
							/>
						</Form.Group>
						<Col></Col>
					</Row>
					<Row>
						<Form.Group as={Col} lg={6} md={8} sm={12} className="mb-3" controlId="email">
							<Form.Label>Email</Form.Label>
							<Form.Control
								required
								type="email"
								placeholder="Digite o email"
								name="email"
								value={formData.email}
								onChange={handleInputChange}
								readOnly={context === 'view'}
							/>
						</Form.Group>
						<Col></Col>
					</Row>
					<Row>
						<Form.Group as={Col} lg={6} md={8} sm={12} className="mb-4" controlId="phone">
							<Form.Label>Telefone</Form.Label>
							<Form.Control
								required
								type="text"
								placeholder="Digite o telefone"
								name="phone"
								value={formData.phone}
								onChange={handleInputChange}
								readOnly={context === 'view'}
							/>
						</Form.Group>
						<Col></Col>
					</Row>
					<Row>
						<Col sm={6} md={4} lg={3} className='d-grid'>
							<Button
								md={3} sm={6}
								variant='secondary'
								type='button'
								onClick={() => navigate(-1)}
							>
								{context === 'view' ? 'Voltar' : 'Cancelar'}
							</Button>
						</Col>
						{context !== 'view' &&
							<Col sm={6} md={4} lg={3} className='d-grid mt-2 mt-sm-0'>
								<Button
									variant='primary'
									type='submit'
									disabled={loadingSendCreate || loadingSendEdit}
									onClick={handleSubmit}
								>
									{
										context === 'create' ?
											loadingSendCreate ? 'Cadastrando...' : 'Cadastrar'
											: loadingSendEdit ? 'Salvando...' : 'Salvar'
									}
								</Button>
							</Col>
						}
					</Row>
				</Form>
				{
					error &&
					<Alert variant='danger' className='mt-4'>
						{error}
					</Alert>
				}
			</Container >
		</>
	);
}
