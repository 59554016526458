import { useEffect, useState } from 'react';

export default function useToken() {
	const [token, setToken] = useState(getToken());
	const [username, setUsername] = useState(getUsername());
	const [statusCode, setStatusCode] = useState(null);

	useEffect(() => {
		statusCode === 403 && clearToken();
		setStatusCode(null);
	}, [statusCode]);

	function getToken() {
		const tokenString = sessionStorage.getItem('token');
		if (tokenString === '') {
			return sessionStorage.clear();
		}
		const userToken = JSON.parse(tokenString);
		return userToken;
	}

	function getUsername() {
		const usernameString = sessionStorage.getItem('username');
		const username = JSON.parse(usernameString);
		return username;
	}

	function saveToken(userToken) {
		sessionStorage.setItem('token', JSON.stringify(userToken));
		setToken(userToken);
	}

	function saveUsername(username) {
		sessionStorage.setItem('username', JSON.stringify(username));
		setUsername(username);
	}

	function clearToken() {
		sessionStorage.clear();
		setToken(null);
	}

	return {
		setToken: saveToken,
		setUsername: saveUsername,
		setStatusCode,
		clearToken,
		token,
		getToken,
		username
	}
}
