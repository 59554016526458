import { useState, useEffect } from 'react';

export default function Certification({ data }) {
	const [loaded, setLoaded] = useState(false);
	const { createdAt } = data.enrollments[0].exam;
	const date = new Date(createdAt);

	useEffect(() => {
		loaded && window.print();
	}, [loaded]);

	return (
		<div className='certification'>
			{!loaded ? 'Carregando...'
				:
				<div className='info'>
					<span className='congratulates'>
						Parabeniza
					</span>
					<span className='name'>
						{data.name}
					</span>
					<span className='completed'>
						Por ter completado com sucesso o treinamento
					</span>
					<span className='title'>
						{data.enrollments[0].training.title}
					</span>
					<span className='date'>
						Em {date.getDate()} de {date.toLocaleString('pt-BR', { month: 'long' })} de {date.getFullYear()}.
					</span>
				</div>
			}
			<img
				src='https://prioriza.eng.br/certification.png'
				alt='Certificado Prioriza'
				onLoad={() => setLoaded(true)}
			/>

		</div>
	);
}